<div class="flex max-w-full flex-col items-center">
  <div class="w-full">
    <div class="mx-auto max-w-[920px] p-2">
      <div class="flex" *ngIf="isSkeletonLoading">
        <p-skeleton
          width="10rem"
          height="4rem"
          styleClass="rounded-none"
        ></p-skeleton>
        <p-skeleton
          width="10rem"
          height="4rem"
          styleClass="rounded-none"
        ></p-skeleton>
      </div>
      <div
        class="grow-1 mb-2 mt-2 flex gap-2"
        *ngIf="summary && !isSkeletonLoading"
      >
        <p-selectButton
          class="hidden md:block"
          [options]="selectButtonOptions"
          (onChange)="onSelectAction($event)"
          [(ngModel)]="selectedButton"
          styleClass="mp-selectbutton"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
        <div class="mb-2 flex flex-wrap gap-2">
          <div
            class="block md:hidden"
            *ngFor="let _selectedButton of selectButtonOptions"
          >
            <p-radioButton
              name="participant_type"
              [value]="_selectedButton.value"
              [(ngModel)]="selectedButton"
              [inputId]="_selectedButton.value"
              (ngModelChange)="onSelectAction($event)"
            ></p-radioButton>
            <label [for]="_selectedButton.value" class="ml-2">{{
              _selectedButton.label
            }}</label>
          </div>
        </div>
      </div>
      <p-skeleton
        width="100%"
        height="5rem"
        styleClass="mt-2"
        *ngIf="isSkeletonUserLoading"
      ></p-skeleton>
      <p-card
        *ngIf="participants.length && summary && !isSkeletonUserLoading"
        class="followers mx-auto block md:mx-0 md:my-auto"
      >
        <div class="followers-row">
          <div
            class="search-results"
            infinite-scroll
            [infiniteScrollDistance]="1"
            [infiniteScrollUpDistance]="2"
            [infiniteScrollThrottle]="500"
            [infiniteScrollContainer]="'.mat-drawer-content'"
            (scrolled)="onScrolledDown()"
            [fromRoot]="true"
          >
            <ul role="list" class="">
              <li
                class="grow-1 flex-stretch group mb-2 ml-12 flex items-center justify-between border-b pb-3 last:mb-0 last:border-b-0 last:pb-0"
                *ngFor="let huddler of participants"
              >
                <div class="grow-1 -ml-12 flex rounded-md px-1 py-1">
                  <p-avatar styleClass="mr-2" size="large" shape="circle">
                    <img
                      *ngIf="huddler?.user?.profilePicUrl"
                      [src]="
                        getProfileImageById(huddler?.user?.id || 0)
                          | image
                          | async
                      "
                      alt=""
                    />
                    <img
                      *ngIf="!huddler?.user?.profilePicUrl"
                      src="../assets/images/user-pic-default.svg"
                      alt="default"
                    />
                  </p-avatar>
                  <div class="flex flex-col">
                    <div>
                      <span *ngIf="huddler?.user"
                        >{{ huddler?.user?.displayName }}&nbsp;</span
                      >
                      <span
                        *ngIf="huddler?.role && huddler?.role !== 'UNDECIDED'"
                      >
                        ({{ roles && roles[huddler?.role || ""] }})
                      </span>
                    </div>
                    <div
                      class="truncate"
                      *ngIf="huddler?.user?.type === 'USER'"
                    >
                      <a
                        [routerLink]="['/', huddler?.user?.username]"
                        class="primary transition duration-300"
                        >&commat;{{ huddler?.user?.username }}</a
                      >
                    </div>
                    <div
                      class="truncate"
                      *ngIf="huddler?.user?.type === 'GUEST'"
                    >
                      <span class="primary">{{ huddler?.user?.email }}</span>
                    </div>
                  </div>
                </div>

                <div class="flex items-center">
                  <button
                    *ngIf="
                      (huddleView.participation?.role === 'HOST' ||
                        huddleView.participation?.role === 'CO_HOST') &&
                      selectedButton === 'waiting_list'
                    "
                    pButton
                    (click)="approveRequest(huddler.id)"
                    class="mp-button mp-button-filled ml-12 h-[40px] rounded-md px-5 py-2 leading-6 shadow-sm md:ml-0"
                  >
                    {{ "Approve" }}
                  </button>
                  <button
                    *ngIf="
                      (huddleView.participation?.role === 'HOST' &&
                        huddleView.selfHuddler?.id !== huddler.id) ||
                      (huddleView.participation?.role === 'CO_HOST' &&
                        huddleView.selfHuddler?.id !== huddler.id &&
                        huddler.role !== 'HOST') ||
                      (huddleView.participation?.requested &&
                        huddleView.participation?.user?.id ===
                          huddler.user.id &&
                        selectedButton === 'waiting_list')
                    "
                    pButton
                    (click)="
                      onMenuToggle(
                        $event,
                        (huddleView.participation?.role === 'HOST' ||
                          huddleView.participation?.role === 'CO_HOST') &&
                          selectedButton === 'invites'
                          ? 'withdraw_invite'
                          : (huddleView.participation?.role === 'HOST' ||
                                huddleView.participation?.role === 'CO_HOST') &&
                              (selectedButton === 'confirmed' ||
                                selectedButton === 'tenative')
                            ? 'remove_participant'
                            : huddleView.participation?.requested
                              ? 'withdraw_request'
                              : 'decline_request',
                        huddler.id
                      )
                    "
                    class="ml-auto rounded-full p-1 shadow-md"
                  >
                    <span class="material-icons">more_horiz</span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </p-card>
      <div
        *ngIf="(!participants.length || !summary) && !isSkeletonUserLoading"
        class="mt-4 ps-2"
      >
        <span *ngIf="selectedButton === 'confirmed'"
          >No one confirmed yet.</span
        >
        <span *ngIf="selectedButton === 'tenative'">No one is tenative.</span>
        <span *ngIf="selectedButton === 'interested'"
          >No one has shown any interest yet.</span
        >
        <span *ngIf="selectedButton === 'declined'">No one declined.</span>
        <span *ngIf="selectedButton === 'waiting_list'"
          >No one is in Waiting List.</span
        >
        <span *ngIf="selectedButton === 'invites'">No invited user.</span>
      </div>
    </div>
  </div>
</div>

<p-tieredMenu
  #menu
  [model]="huddleMoreOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      (click)="onMenuItemClick(item.value)"
      class="p-menuitem-link justify-content-between align-items-center flex p-3"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-tieredMenu>

<p-dialog
  #removeUserDialog
  [(visible)]="displayRemoveUserDialog"
  styleClass="md:w-5/12 w-10/12"
  appendTo="body"
  [modal]="true"
>
  <ng-template pTemplate="header">
    <h3 class="h-level-3">Confirmation</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="mt-2">
      Are you sure you want to remove this user from this huddle?
    </p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="mt-2 flex flex-col justify-start gap-4 xl:flex-row">
      <div class="order-2 flex-1 text-left xl:order-none">
        <button
          pButton
          (click)="closeDialog()"
          class="mp-button mp-button-outlined w-full justify-center rounded-md border px-5 py-2 leading-6 transition-all xl:w-auto"
        >
          Close
        </button>
      </div>
      <button
        pButton
        (click)="removeParticipant()"
        class="mp-button mp-button-filled m-1 justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Remove
      </button>
    </div>
  </ng-template>
</p-dialog>
