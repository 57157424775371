<div
  class="huddle-container mx-auto max-w-full pb-8 lg:container"
  role="main"
  *ngIf="!isLoading"
>
  <div class="surface-ground mb-12 flex flex-col">
    <p-card styleClass="rounded-none huddle-view padding-clear">
      <div class="relative m-auto flex w-full items-center items-stretch">
        <div class="bg-blur absolute h-[200px] w-full overflow-hidden">
          <img
            [src]="coverPhotoUrl | image | async"
            alt="huddleView.name"
            *ngIf="huddleView.coverPhotoUrl"
            [ngStyle]="{
              'top.px': 0,
            }"
            class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
          />
          <img
            *ngIf="!huddleView.coverPhotoUrl"
            [src]="
              activityEnum.Hiking !== huddleView.activity
                ? '../assets/images/huddle-default-cover.svg'
                : '../assets/images/huddle-default-cover-hiking.svg'
            "
            alt=""
            class="static -bottom-[60px] min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
          />
        </div>
        <div
          class="relative mx-auto flex w-full max-w-[860px] items-center items-stretch"
        >
          <div
            class="relative flex h-[200px] w-full items-center justify-center overflow-hidden"
          >
            <img
              [src]="coverPhotoUrl | image | async"
              alt="huddleView.name"
              *ngIf="huddleView.coverPhotoUrl"
              [ngStyle]="{
                'top.px': huddleView.coverPhotoCoordinate?.ycoordinate,
              }"
              class="static top-0 min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
            />
            <img
              *ngIf="!huddleView.coverPhotoUrl"
              [src]="
                activityEnum.Hiking !== huddleView.activity
                  ? '../assets/images/huddle-default-cover.svg'
                  : '../assets/images/huddle-default-cover-hiking.svg'
              "
              alt=""
              class="static -bottom-[60px] min-h-full min-w-full shrink-0 object-cover object-top sm:absolute"
            />
          </div>
        </div>
      </div>
      <div
        class="relative m-auto flex w-full max-w-[860px] items-center items-stretch pb-[16px]"
      >
        <div class="shrink-1 grow-1 flex w-full min-w-0 flex-col">
          <div class="mt-6 flex h-0 items-end" *ngIf="huddleView.startDateTime">
            <div
              class="relative ml-4 flex shrink items-stretch justify-between pr-[12px] pt-[16px]"
            >
              <div
                class="shrink-1 relative flex min-w-0 max-w-full flex-col pb-[6px] pt-[6px]"
              >
                <div
                  class="flex h-[80px] w-[80px] flex-col items-stretch overflow-hidden rounded-bl-md rounded-br-md"
                  style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
                >
                  <div
                    class="h-[28px] rounded-tl-md rounded-tr-md bg-rose-500 py-1 text-center text-white"
                  >
                    {{ eventLongDate | date: "MMM" }}
                  </div>
                  <div
                    class="flex h-[60px] items-center justify-center bg-white text-4xl font-bold text-black"
                  >
                    {{ eventDate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="space-between flex items-end px-2 pt-[16px]">
              <div class="shrink-1 grow-1 flex max-w-full flex-col pb-[8px]">
                <span
                  *ngIf="huddleView.startDateTime"
                  class="primary mb-2 uppercase"
                  >{{ formatDate(eventLongDate, eventLongDateEnd) }}</span
                >
                <span class="primary mb-2" *ngIf="!huddleView.startDateTime"
                  >No date selected</span
                >
                <h2 class="h-level-2 font-semibold">
                  {{ huddleView.name }}
                </h2>
                <h3 class="h-level-3" *ngIf="huddleView.communityId">
                  {{ huddleView.communityObj.name }}
                </h3>
                <h3 class="h-level-3 gray-400 my-2">
                  {{
                    huddleView.venue
                      ? huddleView.venue.name
                      : "No location selected"
                  }}
                </h3>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-end gap-3 px-2">
            <div class="" *ngIf="!['YES', 'MAYBE'].includes(rsvpType)">
              <button
                pButton
                [disabled]="isPastHuddle"
                type="button"
                (click)="saveInterest(!interested)"
                [ngClass]="interested ? '' : 'hover:bg-teal-500'"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span
                  [ngClass]="!interested ? 'hidden group-hover:block' : 'block'"
                  class="material-icons"
                >
                  star
                </span>
                <span
                  class="material-icons"
                  [ngClass]="
                    !interested ? 'block group-hover:hidden' : 'hidden'
                  "
                >
                  star_outline
                </span>
                <span class="hidden sm:block">Interested</span>
              </button>
            </div>
            <div
              class="flex"
              *ngIf="
                huddleView.participation?.requested ||
                huddleView.participation?.waiting
              "
            >
              <button
                pButton
                type="button"
                [disabled]="isPastHuddle"
                [ngClass]="'hover:bg-teal-500'"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-md rounded-br-none rounded-tr-none px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons"> hourglass_top </span>
                <span>{{
                  huddleView.participation?.requested ? "Requested" : "Waiting"
                }}</span>
              </button>
              <button
                pButton
                type="button"
                (click)="requested.toggle($event)"
                class="mp-button mp-button-outlined h-12 w-4/12 justify-center rounded-bl-none rounded-tl-none border-l-0 px-2 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons"> expand_more </span>
              </button>
            </div>
            <div
              class="flex"
              *ngIf="
                huddleView.participation?.rsvped ||
                !(
                  huddleView.participation?.requested ||
                  huddleView.participation?.waiting
                )
              "
            >
              <button
                pButton
                type="button"
                [disabled]="isPastHuddle"
                (click)="saveRsvp('YES')"
                [ngClass]="rsvpType === 'YES' ? '' : 'hover:bg-teal-500'"
                class="mp-button mp-button-outlined group h-12 w-full justify-center gap-2 rounded-br-none rounded-tr-none px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span
                  [ngClass]="
                    rsvpType !== 'YES'
                      ? 'hidden group-hover:block'
                      : 'enabled block'
                  "
                  class="material-icons"
                >
                  check_circle
                </span>
                <span
                  class="material-icons"
                  [ngClass]="
                    rsvpType !== 'YES' ? 'block group-hover:hidden' : 'hidden'
                  "
                >
                  check_circle_outline
                </span>
                <span class="prevent">{{
                  huddleView.participation?.invited &&
                  !huddleView.participation?.rsvped
                    ? "Accept"
                    : rsvpType === "YES"
                      ? "Confirmed"
                      : "Confirm"
                }}</span>
              </button>
              <button
                pButton
                [disabled]="isPastHuddle"
                type="button"
                (click)="going.toggle($event)"
                class="mp-button mp-button-outlined h-12 w-4/12 justify-center rounded-bl-none rounded-tl-none border-l-0 px-2 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons"> expand_more </span>
              </button>
            </div>
            <div class="hidden md:block">
              <button
                pButton
                [disabled]="isPastHuddle"
                type="button"
                (click)="inviteVisible = !0"
                class="mp-button mp-button-outlined h-12 w-full justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                <span class="material-icons">mail</span>
                <span class="hidden xl:block">Invite</span>
              </button>
            </div>
            <div class="flex">
              <button
                pButton
                type="button"
                (click)="menu.toggle($event)"
                class="mp-button mp-button-outlined h-12 w-full items-baseline justify-center rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
              >
                ...
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative m-auto flex w-full max-w-[860px] items-center items-stretch"
      >
        <div class="w-full border-t border-t-gray-300"></div>
      </div>

      <div class="sticky z-auto">
        <div
          class="relative m-auto flex w-full max-w-[860px] flex-col items-start justify-between gap-0 pb-0 lg:flex-row xl:items-center"
        >
          <p-tabMenu
            [scrollable]="true"
            [model]="tabs"
            [activeItem]="activeTab"
            styleClass="mp-menu"
            (activeItemChange)="onActiveItemChange($event)"
            class="w-full md:w-auto"
          ></p-tabMenu>
          <div
            class="flex max-w-[352px] flex-wrap gap-4 md:max-w-fit"
            *ngIf="activeTab?.label === 'Overview'"
          ></div>
        </div>
      </div>
    </p-card>
    <div
      class="relative"
      [ngClass]="{ hidden: activeTab?.label !== 'Overview' }"
    >
      <app-view-about
        [huddleId]="huddleId"
        [huddleView]="huddleView"
        (view)="onView($event)"
        (huddlers)="onHuddlers($event)"
        [events]="huddleViewEvent.asObservable()"
      >
      </app-view-about>
    </div>

    <div class="relative" *ngIf="activeTab?.label === 'Carpools'">
      <app-view-carpools
        [huddleId]="huddleId"
        [huddleView]="huddleView"
        [viewCarpoolEvent]="viewCarpoolEvent.asObservable()"
        (editCarpool)="editCarpool($event)"
        [userInfo]="userInfo"
      ></app-view-carpools>
    </div>

    <div class="relative" *ngIf="activeTab?.label === 'Expenses'">
      <div class="py-4">
        <h4 class="text-center text-xl">Expenses Feature Coming Soon</h4>
        <p class="pt-2 text-center">
          Stay tuned! An Expenses feature is planned for a future release of
          Mission Peak to make managing expenses even easier.
        </p>
      </div>
    </div>

    <div class="relative" *ngIf="activeTab?.label === 'Potluck'">
      <app-huddle-view-potluck></app-huddle-view-potluck>
    </div>

    <div
      class="relative"
      [ngClass]="{ hidden: activeTab?.label !== 'Participants' }"
    >
      <app-view-participants
        [huddleView]="huddleView"
        [selectedTab]="selectedButtonTab"
        [participantEvent]="huddleParticipantEvent.asObservable()"
      >
      </app-view-participants>
    </div>

    <div *ngIf="activeTab?.label === 'Settings'">
      <app-view-settings></app-view-settings>
    </div>

    <div *ngIf="activeTab?.label === 'Messages'">
      <div class="py-4">
        <h4 class="text-center text-xl">Messages Coming Soon</h4>
        <p class="pt-2 text-center">
          Stay tuned! A messaging feature is planned for future release of
          MissionPeak to make communication even easier.
        </p>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="carpoolVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '960px': '80vw' }"
  [appendTo]="'body'"
  styleClass="dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
>
  <div
    *ngIf="carpoolFormGroup.valid && submitted"
    class="absolute left-0 top-0 z-100 h-full w-full bg-white opacity-40"
  ></div>
  <ng-template pTemplate="header">
    <p-progressBar
      *ngIf="carpoolFormGroup.valid && submitted"
      class="absolute left-0 top-0 w-full"
      mode="indeterminate"
      [style]="{ height: '6px' }"
    ></p-progressBar>
    <span class="text-xl font-bold">
      <span>{{ isCreate ? "Create" : "View" }} Carpool</span>
      <p-button
        class="inline-block align-text-bottom"
        [label]="isViewMode ? '(Edit)' : '(View)'"
        *ngIf="!isCreate"
        styleClass="text-sm text-teal-500 ml-3"
        [text]="true"
        (click)="isViewMode = !isViewMode"
      ></p-button>
    </span>
  </ng-template>
  <form [formGroup]="carpoolFormGroup" (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 grid grid-cols-1 gap-x-6 gap-y-2 md:col-span-6">
        <div class="col-span-6 md:col-span-6">
          <label
            for="username"
            class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
            >Carpool Leader</label
          >
          <div class="mt-2 flex items-center gap-2">
            <p-avatar
              [image]="profilePic"
              styleClass="mr-2"
              size="large"
              shape="circle"
            ></p-avatar>
            <div class="text-base">{{ leaderName }}</div>
            <div class="text-sm text-slate-400">(Leader)</div>
          </div>
        </div>

        <div class="col-span-6 md:col-span-6">
          <label
            for="about"
            class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
            >Communication Channel URL</label
          >
          <div [ngClass]="{ 'mt-2': !isViewMode }">
            <input
              pInputText
              formControlName="externalCommChannelUrl"
              type="text"
              *ngIf="!isViewMode"
              placeholder="https://chat.whatsapp.com/H86M3sqR6FU50v0ADIclFL"
              class="block h-12 w-full rounded-md border-0 bg-white px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254)] focus-visible:outline-0 dark:border-slate-800 dark:bg-slate-900 dark:text-slate-400 dark:text-slate-500 sm:text-sm sm:leading-6"
            />
            <a
              [href]="
                this.carpoolFormGroup.get('externalCommChannelUrl')?.value
              "
              class="text-teal-500"
              target="_blank"
            >
              <span *ngIf="isViewMode" class="text-base">{{
                this.carpoolFormGroup.get("externalCommChannelUrl")?.value ||
                  "-"
              }}</span>
            </a>
          </div>
        </div>

        <div class="col-span-6 md:col-span-6">
          <label
            for="about"
            class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
            >Vehicles <span class="text-red-400">*</span></label
          >
          <div [ngClass]="{ 'mt-2': !isViewMode }">
            <div
              *ngIf="!isViewMode"
              class="flex flex-1 rounded-md border border-gray-300 shadow-sm"
              [ngClass]="{
                'border-red-300': submitted && f['vehicle'].errors,
              }"
            >
              <p-dropdown
                styleClass="dark:text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 dark:border-slate-800 dark:border w-full"
                class="w-full"
                formControlName="vehicle"
                [options]="vehicles"
                optionLabel="name"
                placeholder="Select Vehicle"
              >
                <ng-template let-item pTemplate="item">
                  <div class="align-items-center flex flex-col gap-2">
                    <span>{{ item.name }}</span>
                    <span>{{ item.category }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <span *ngIf="isViewMode" class="text-base">{{
              this.carpoolFormGroup.get("vehicle")?.value?.name || "-"
            }}</span>
            <div
              *ngIf="submitted && f['vehicle'].errors"
              class="invalid-feedback text-red-500"
            >
              <small *ngIf="f['vehicle'].errors['required']"
                >Type is required</small
              >
            </div>
          </div>
        </div>

        <div class="col-span-6 md:col-span-6">
          <label
            for="about"
            class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
            >Trip Type <span class="text-red-400">*</span></label
          >
          <div [ngClass]="{ 'mt-2': !isViewMode }">
            <div
              class="flex flex-1 rounded-md border border-gray-300 shadow-sm"
              *ngIf="!isViewMode"
              [ngClass]="{
                'border-red-300': submitted && f['tripType'].errors,
              }"
            >
              <p-dropdown
                styleClass="dark:text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 dark:border-slate-800 dark:border w-full"
                class="w-full"
                formControlName="tripType"
                [options]="tripType"
                optionLabel="name"
                (onChange)="onTripTypeChange($event)"
                placeholder="Select Trip Type"
              ></p-dropdown>
            </div>
            <span *ngIf="isViewMode" class="text-base">{{
              this.carpoolFormGroup.get("tripType")?.value.name || "-"
            }}</span>
            <div
              *ngIf="submitted && f['tripType'].errors"
              class="invalid-feedback text-red-500"
            >
              <small *ngIf="f['tripType'].errors['required']"
                >Trip Type is required</small
              >
            </div>
          </div>
        </div>

        <div class="col-span-6 md:col-span-6">
          <div class="mt-2">
            <!-- <app-address
              *ngIf="carpoolVisible"
              label="Start Address"
              [hideMap]="true"
              (renderAddress)="renderAddress()"
              [events]="startAddressSubject.asObservable()"
              (addressChange)="onStartAddressChange($event)"
            ></app-address> -->
          </div>
        </div>

        <div class="col-span-6 md:col-span-6">
          <div class="mt-2">
            <!-- <app-address
              *ngIf="carpoolVisible"
              label="End Address"
              [hideMap]="true"
              (renderAddress)="renderAddress()"
              [events]="endAddressSubject.asObservable()"
              (addressChange)="onEndAddressChange($event)"
            ></app-address> -->
          </div>
        </div>

        <div
          class="col-span-6 md:col-span-6"
          [ngClass]="{
            hidden: !this.carpoolFormGroup.get('endAddress')?.value,
          }"
        >
          <div class="mt-2">
            <!-- <div id="gmap-route" class="gmap-route h-[250px]"></div> -->
          </div>
        </div>

        <div class="col-span-6 md:col-span-6">
          <label
            class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
            >Start Date</label
          >
          <div
            class="flex h-12 rounded-md border border-gray-300"
            *ngIf="!isViewMode"
          >
            <p-calendar
              inputStyleClass="dark:text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 dark:border-slate-800"
              class="w-full"
              formControlName="startTime"
              [showButtonBar]="true"
              [showTime]="true"
              [showSeconds]="false"
              [hourFormat]="'12'"
              [minDate]="startDate"
              [hideOnDateTimeSelect]="false"
            ></p-calendar>
          </div>
          <span *ngIf="isViewMode" class="text-base">{{
            this.carpoolFormGroup.get("startTime")?.value || "-"
          }}</span>
        </div>

        <div class="col-span-6 md:col-span-6">
          <label
            class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
            >End Date</label
          >
          <div
            class="flex h-12 rounded-md border border-gray-300"
            *ngIf="!isViewMode"
          >
            <p-calendar
              inputStyleClass="dark:text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 dark:border-slate-800"
              class="w-full"
              formControlName="returnTime"
              [showButtonBar]="true"
              [showTime]="true"
              [showSeconds]="false"
              [hourFormat]="'12'"
              [minDate]="startDate"
              [hideOnDateTimeSelect]="false"
            ></p-calendar>
          </div>
          <span *ngIf="isViewMode" class="text-base">{{
            this.carpoolFormGroup.get("returnTime")?.value || "-"
          }}</span>
        </div>

        <div class="col-span-6 md:col-span-6">
          <label
            for="about"
            class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
            >Notes</label
          >
          <div class="mt-2" *ngIf="!isViewMode">
            <textarea
              pInputTextarea
              rows="5"
              cols="30"
              formControlName="notes"
              class="block w-full rounded-md border-0 bg-white px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus-visible:outline-0 dark:border-slate-800 dark:bg-slate-900 dark:text-slate-400 dark:text-slate-500 sm:text-sm sm:leading-6"
            ></textarea>
          </div>
          <span *ngIf="isViewMode" class="text-base">{{
            this.carpoolFormGroup.get("notes")?.value || "-"
          }}</span>
        </div>

        <div class="col-span-6 md:col-span-6">
          <div class="mt-2">
            <div
              class="flex flex-1 gap-4 rounded-md"
              [ngClass]="{
                'border-red-300': submitted && f['tripType'].errors,
              }"
            >
              <p-checkbox
                formControlName="oneWayAllowed"
                [binary]="true"
                value="ONE_WAY_ALLOWED"
                inputId="one-way"
                *ngIf="!isViewMode"
              ></p-checkbox>
              <label
                for="one-way"
                class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
                >One way allowed</label
              >
              <span *ngIf="isViewMode" class="text-base">{{
                this.carpoolFormGroup.get("oneWayAllowed")?.value ? "Yes" : "No"
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-span-6 md:col-span-6">
          <div class="mt-2">
            <div
              class="flex flex-1 gap-4 rounded-md"
              [ngClass]="{
                'border-red-300': submitted && f['tripType'].errors,
              }"
            >
              <p-checkbox
                formControlName="joinRequestRequired"
                [binary]="true"
                value="JOIN_REQUEST"
                inputId="join-request"
                *ngIf="!isViewMode"
              ></p-checkbox>
              <label
                for="join-request"
                class="block text-base font-medium leading-6 text-gray-900 dark:text-slate-500"
                >Join request required</label
              >
              <span *ngIf="isViewMode" class="text-base">{{
                this.carpoolFormGroup.get("joinRequestRequired")?.value
                  ? "Yes"
                  : "No"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-6 grid grid-cols-1 gap-x-6 gap-y-2 md:col-span-6">
        <div class="flex items-center justify-end gap-3 pt-[1.25rem]">
          <p-button
            label="Reset"
            (click)="resetForm()"
            *ngIf="!isViewMode && isCreate"
            styleClass="text-sm font-semibold leading-6 text-gray-900 border border-teal-500 px-5 py-2 rounded-md transition-all hover:bg-teal-500 dark:text-slate-500 hover:text-white hover:dark:text-white"
          ></p-button>
          <p-button
            type="submit"
            label="Save"
            *ngIf="!isViewMode && isCreate"
            [disabled]="!carpoolFormGroup.valid"
            styleClass="rounded-md bg-teal-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 leading-6"
          >
          </p-button>
          <p-button
            type="submit"
            label="Update"
            *ngIf="!isViewMode && !isCreate"
            [disabled]="!carpoolFormGroup.valid"
            styleClass="rounded-md bg-teal-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 leading-6"
          >
          </p-button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-menu
  #menu
  [model]="moreOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      [ngClass]="{
        'pointer-events-none opacity-40': item.disabled,
        'block md:hidden': item?.hideInMobile,
      }"
      class="p-menuitem-link justify-content-between align-items-center flex p-3"
      (click)="onOverlayMenuClick(item.id)"
    >
      <div>
        <span [class]="item.icon">
          <fa-icon
            [icon]="item.icon"
            size="1x"
            styleClass="highlight-text"
          ></fa-icon>
        </span>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>
<p-menu
  #going
  [model]="goingOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      (click)="
        huddleView.participation?.role === 'HOST'
          ? handleMenuClick(item)
          : saveRsvp(item.value === 'tentative' ? 'MAYBE' : 'NO')
      "
      class="p-menuitem-link p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div class="flex items-center">
        <span
          class="material-icons"
          [ngClass]="
            (rsvpType === 'MAYBE' && item.label === 'Tentative') ||
            (rsvpType === 'NO' && item.label !== 'Tentative')
              ? 'text-teal-500'
              : ''
          "
        >
          {{
            item.label === "Tentative" ? "do_not_disturb_on" : "cancel"
          }}</span
        >
        <span class="ml-2">
          {{
            rsvpType === "NO" && item.label === "Decline"
              ? "Declined"
              : item.label
          }}</span
        >
      </div>
    </a>
  </ng-template>
</p-menu>

<p-menu
  #requested
  [model]="requestedOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      (click)="onOverlayMenuClick(item.id)"
      class="p-menuitem-link p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div class="flex items-center">
        <span class="material-icons">cancel</span>
        <span class="ml-2">Withdraw</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<p-dialog
  #declineDialog
  [(visible)]="displayDeclineDialog"
  styleClass="md:w-5/12 w-10/12"
  appendTo="body"
  [modal]="true"
>
  <ng-template pTemplate="header">
    <h3 class="h-level-3">Confirmation</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="mt-2">
      You are the host of this huddle. So, you cannot decline to this huddle.
      You can either make someone else host and decline or you can just simply
      cancel the huddle.
    </p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="mt-2 flex flex-col justify-start gap-4 xl:flex-row">
      <div class="order-2 flex-1 text-left xl:order-none">
        <button
          pButton
          (click)="closeDialog()"
          class="mp-button mp-button-outlined w-full justify-center rounded-md border px-5 py-2 leading-6 transition-all xl:w-auto"
        >
          Close
        </button>
      </div>
      <button
        pButton
        (click)="cancelHuddle()"
        class="mp-button mp-button-filled m-1 justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Cancel The Huddle
      </button>
      <button
        pButton
        (click)="chooseNewHost()"
        class="mp-button mp-button-filled m-1 justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Choose a new Host
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog #rsvp styleClass="md:w-5/12 w-10/12" appendTo="body">
  <ng-template pTemplate="header">
    <h3 class="h-level-3">
      {{ menuId === "cancel" ? "Cancel Huddle" : "Confirm RSVP" }}
    </h3>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="mt-2">
      {{
        menuId !== "cancel"
          ? "User is participating in the carpool and will be removed!"
          : "Do you want to cancel the huddle?"
      }}
    </p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="mt-2 flex flex-col justify-end gap-4 xl:flex-row">
      <button
        pButton
        (click)="rsvp.reject()"
        class="mp-button mp-button-outlined justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Cancel
      </button>
      <button
        pButton
        (click)="
          menuId === 'cancel' ? cancelHuddle() : onRSVPClick('YES', null)
        "
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Ok
      </button>
    </div>
  </ng-template>
</p-confirmDialog>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<app-sharelink
  *ngIf="huddleView"
  [event]="shareLinkEvent.asObservable()"
  objectType="HUDDLE"
  [objectId]="huddleView.id"
></app-sharelink>

<app-invite-dialog
  [(visible)]="inviteVisible"
  buttonLabel="Invite"
  (buttonClick)="invite($event)"
></app-invite-dialog>

<app-rsvp-dialog
  *ngIf="huddleView"
  [huddleView]="huddleView"
  [rsvpType]="prevRsvpType"
  [userInfo]="userInfo"
  [(visible)]="guestVisible"
  (rsvpChange)="onRsvpChange($event)"
  [width]="
    huddleView.paymentMode === 'PAID' && settings.guestAllowed ? '50vw' : '30vw'
  "
>
</app-rsvp-dialog>
