import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { data } from 'cypress/types/jquery';
import { remove } from 'cypress/types/lodash';
import { MessageService } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';
import { Observable } from 'rxjs';
import { HuddleService } from 'src/app/main/services/huddle.service';
import {
  Huddle,
  Huddlers,
  Member,
  ParticipantSummary,
  User,
} from 'src/app/main/types/main.types';
import { roles } from 'src/app/main/utilities/sidebarHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-participants',
  templateUrl: './huddle-participants.component.html',
})
export class HuddleViewParticipantsComponent implements OnInit, OnChanges {
  selectButtonOptions: any[];

  huddleMoreOptions: any[];

  isSkeletonLoading = true;

  isSkeletonUserLoading = true;

  selectedButton = 'confirmed';

  confirmed: Huddlers[];

  tenative: Huddlers[];

  interested: Huddlers[];

  participants: Huddlers[] = [];

  currentPage = 1;

  inviteType = 'users';

  summary: ParticipantSummary | undefined;

  roles: Record<string, string> = roles;

  huddleParticipantInvitedId: number;

  huddleParticipantRequestId: number;

  huddleParticipantId: number;

  displayRemoveUserDialog = false;

  @Input() huddleView: Huddle;

  @Input() selectedTab: string;

  @Input() participantEvent: Observable<string>;

  @ViewChild('menu', { static: false }) menuRef: TieredMenu;

  constructor(
    private huddleService: HuddleService,
    private messageService: MessageService,
  ) {
    this.selectButtonOptions = [
      {
        label: 'Confirmed',
        value: 'confirmed',
        prop: 'showRsvpedYesParticipants',
      },
      {
        label: 'Tenative',
        value: 'tenative',
        prop: 'showRsvpedMaybeParticipants',
      },
      {
        label: 'Interested',
        value: 'interested',
        prop: 'showInterestedParticipants',
      },
      { label: 'Invites', value: 'invites', prop: 'showInvitedParticipants' },
      {
        label: 'Declined',
        value: 'declined',
        prop: 'showRsvpedNoParticipants',
      },
      {
        label: 'Waiting List',
        value: 'waiting_list',
        prop: 'showRequestedParticipants',
      },
    ];

    this.huddleMoreOptions = [
      {
        label: 'Withdraw',
        value: 'withdraw_invite',
        prop: 'withdrawInvitedParticipant',
      },
      {
        label: 'Withdraw',
        value: 'withdraw_request',
        prop: 'withdrawJoinRequest',
      },
      {
        label: 'Decline',
        value: 'decline_request',
        prop: 'declineRequestedParticipant',
      },
      {
        label: 'Remove',
        value: 'remove_participant',
        prop: 'removeHuddleParticipant',
      },
    ];
  }

  onMenuToggle(event: Event, item: string, Id: number) {
    if (item === 'withdraw_invite') {
      this.huddleMoreOptions = this.huddleMoreOptions.filter(
        (c) => c.value === item,
      );
      this.huddleParticipantInvitedId = Id;
    }

    if (item === 'withdraw_request') {
      this.huddleMoreOptions = this.huddleMoreOptions.filter(
        (c) => c.value === item,
      );
    }

    if (item === 'decline_request') {
      this.huddleMoreOptions = this.huddleMoreOptions.filter(
        (c) => c.value === item,
      );
      this.huddleParticipantRequestId = Id;
    }

    if (item === 'remove_participant') {
      this.huddleMoreOptions = this.huddleMoreOptions.filter(
        (c) => c.value === item,
      );
      this.huddleParticipantId = Id;
    }

    this.menuRef.toggle(event);
  }

  onMenuItemClick(value: string): void {
    switch (value) {
      case 'withdraw_invite':
        this.withdrawInvite(
          '' + this.huddleView.id,
          '' + this.huddleParticipantInvitedId,
        );
        break;
      case 'withdraw_request':
        this.withdrawRequest();
        break;
      case 'decline_request':
        this.declineRequest();
        break;
      case 'remove_participant':
        this.handleMenuClick();
        break;
    }
  }

  ngOnInit(): void {
    this.participantEvent.subscribe((tab: string) => {
      this.participants = [];
      this.summary = undefined;
      this.isSkeletonLoading = true;
      this.isSkeletonUserLoading = true;
      this.getParticipantsSummary();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedTab'] &&
      changes['selectedTab'].previousValue !==
        changes['selectedTab'].currentValue
    ) {
      this.selectedButton = changes['selectedTab'].currentValue;
      this.onSelectAction({ value: this.selectedButton });
    }
  }

  onSelectAction(e: any) {
    this.participants = [];
    this.currentPage = 1;
    this.isSkeletonUserLoading = true;
    const value = e.value ? e.value : e;
    switch (value) {
      case 'confirmed':
        this.getRsvped('YES');
        break;
      case 'tenative':
        this.getRsvped('MAYBE');
        break;
      case 'interested':
        this.getInterested();
        break;
      case 'declined':
        this.getRsvped('NO');
        break;
      case 'invites':
        this.getInvitedUsers();
        break;
      case 'waiting_list':
        this.getWaitingList();
        break;
    }
  }

  getInvitedUsers() {
    this.huddleService
      .getInvitedUsers('' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  getRsvped(rsvpType: string) {
    this.huddleService
      .getRSVPed(rsvpType, '' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  getInterested() {
    this.huddleService
      .getInterested('' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  getWaitingList() {
    this.huddleService
      .getWaitingList('' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  handleCatch(err: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Huddle Participants',
      detail: err.message,
    });
    console.error('Request to get carpools errored out.' + err.message);
  }

  getProfileImageById(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  getParticipantsSummary() {
    this.huddleService
      .getParticipantsSummary('' + this.huddleView.id)
      .subscribe({
        next: (data: any) => {
          this.summary = data;
          this.selectButtonOptions = this.selectButtonOptions.filter(
            (options) => {
              const prop = options.prop;
              if (data[prop]) {
                return options;
              }
            },
          );
          this.onSelectAction(this.selectedButton);
          this.isSkeletonLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  onScrolledDown() {
    this.currentPage++;
  }

  withdrawInvite(huddleId: string, huddleParticipantInviteId: string) {
    this.huddleService
      .withdrawInvite(huddleId, huddleParticipantInviteId)
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data ? 'Success' : 'Fail',
            summary: 'Withdraw invite',
            detail: data.message,
          });
        },
        error: (e) => {
          console.error('Error occured when request to withdraw the invite ');
          console.error(e);
        },
      });
  }

  withdrawRequest() {
    this.huddleService
      .withdrawRequest({ huddleId: '' + this.huddleView.id })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data ? 'Success' : 'Fail',
            summary: 'Withdraw request',
            detail: data.message,
          });
        },
        error: (e) => {
          console.error('Error: withdraw join request fail');
          console.error(e);
        },
      });
  }

  approveRequest(huddleParticipantRequestId: number) {
    this.huddleService
      .approveRequest({
        huddleId: '' + this.huddleView.id,
        huddleParticipantRequestId: '' + huddleParticipantRequestId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data ? 'Success' : 'Fail',
            summary: 'Approve request',
            detail: data.message,
          });
        },
        error: (e: any) => {
          console.error('Error occured when approving the request ');
          console.error(e);
        },
      });
  }

  declineRequest() {
    this.huddleService
      .declineRequest({
        huddleId: '' + this.huddleView.id,
        huddleParticipantRequestId: '' + this.huddleParticipantRequestId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data ? 'Success' : 'Fail',
            summary: 'Decline request',
            detail: data.message,
          });
        },
        error: (e: any) => {
          console.error('Error occured when declining the request ');
          console.error(e);
        },
      });
  }

  closeDialog(): void {
    this.displayRemoveUserDialog = false;
  }

  handleMenuClick(): void {
    this.displayRemoveUserDialog = true;
  }

  removeParticipant() {
    this.displayRemoveUserDialog = false;
    this.huddleService
      .removeParticipant('' + this.huddleView.id, '' + this.huddleParticipantId)
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data ? 'Success' : 'Fail',
            summary: 'Remove participant',
            detail: data.message,
          });
        },
        error: (e: any) => {
          console.error('Error occured in removing participant ');
          console.error(e);
        },
      });
  }
}
