import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-huddle-view-potluck',
  templateUrl: './huddle-view-potluck.component.html',
})
export class HuddleViewPotluckComponent implements OnInit {
  currentCategory = '';
  potluckCategories = [
    {
      category: 'Appetizer',
      items: [
        {
          name: 'Samosa',
          claimedBy: null,
        },
        {
          name: 'Pakodi',
          claimedBy: {
            userid: 123,
            username: 'ashishrky',
            displayName: 'Ashish Kumar',
            profileUrl: 'https://profilelink.com',
          },
        },
        {
          name: 'Spring Rolls',
          claimedBy: {
            userid: 456,
            username: 'johndoe',
            displayName: 'John Doe',
            profileUrl: 'https://profilelink.com/johndoe',
          },
        },
        {
          name: 'Stuffed Mushrooms',
          claimedBy: null,
        },
      ],
    },
    {
      category: 'Main Course',
      items: [
        {
          name: 'Dal Tadka',
          claimedBy: null,
        },
        {
          name: 'Chawal (Rice)',
          claimedBy: null,
        },
        {
          name: 'Butter Chicken',
          claimedBy: {
            userid: 789,
            username: 'neelam',
            displayName: 'Neelam Patel',
            profileUrl: 'https://profilelink.com/neelam',
          },
        },
        {
          name: 'Vegetable Biryani',
          claimedBy: {
            userid: 101,
            username: 'sameer',
            displayName: 'Sameer Khan',
            profileUrl: 'https://profilelink.com/sameer',
          },
        },
      ],
    },
    {
      category: 'Desserts',
      items: [
        {
          name: 'Gulab Jamun',
          claimedBy: null,
        },
        {
          name: 'Chocolate Cake',
          claimedBy: {
            userid: 102,
            username: 'maria',
            displayName: 'Maria Gomez',
            profileUrl: 'https://profilelink.com/maria',
          },
        },
        {
          name: 'Fruit Salad',
          claimedBy: null,
        },
        {
          name: 'Rasgulla',
          claimedBy: null,
        },
      ],
    },
    {
      category: 'Drinks',
      items: [
        {
          name: 'Mango Lassi',
          claimedBy: null,
        },
        {
          name: 'Masala Chai',
          claimedBy: {
            userid: 103,
            username: 'arvind',
            displayName: 'Arvind Singh',
            profileUrl: 'https://profilelink.com/arvind',
          },
        },
        {
          name: 'Lemonade',
          claimedBy: null,
        },
      ],
    },
  ];

  currentItems: {
    name: string;
    claimedBy?: {
      userid: number;
      username: string;
      displayName: string;
      profileUrl: string;
    } | null;
  }[] = [];

  ngOnInit(): void {
    console.log('Component initialized');
  }

  removeItem(categoryIndex: number, itemIndex: number): void {
    // Remove the item from the category's items array
    this.potluckCategories[categoryIndex].items.splice(itemIndex, 1);
  }

  addItemToCategory(categoryIndex: number): void {
    this.potluckCategories[categoryIndex].items.push({
      name: '',
      claimedBy: null,
    });
  }

  addPotluckCategory(): void {
    const defaultTitle = `Category ${this.potluckCategories.length + 1}`;
    const categoryTitle = this.currentCategory.trim() || defaultTitle;

    // Ensure that `currentItems` is initialized with one default item if it's empty
    const defaultItem = { name: 'Enter item name', claimedBy: null };
    const formattedItems =
      this.currentItems.length > 0
        ? this.currentItems.map((item) => ({
            name: item.name || '',
            claimedBy: item.claimedBy || null,
          }))
        : [defaultItem];

    // Push the new category into `potluckCategories`
    this.potluckCategories.push({
      category: categoryTitle,
      items: formattedItems,
    });

    // Reset current inputs
    this.currentCategory = '';
    this.currentItems = [];
  }

  deleteCategory(categoryIndex: number): void {
    this.potluckCategories.splice(categoryIndex, 1);
  }

  resetItems(): void {
    this.currentItems = [];
  }

  onCancel(): void {
    this.currentCategory = '';
    this.currentItems = [];
  }

  saveCategories(): void {
    console.log('Potluck Categories:', this.potluckCategories);
    alert('Potluck categories saved!');
  }
}
