import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationList } from 'src/app/main/types/main.types';
import { notificationsOptions } from 'src/app/main/utilities/overlayHelper';
import { NotificationsService } from 'src/app/main/services/notifications.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { conncetionsMap } from 'src/app/main/utilities';
import { DEFAULT_HOME } from 'src/app/main/utilities';

@Component({
  selector: 'app-notification-overview',
  templateUrl: './notification-overview.component.html',
})
export class NotificationOverviewComponent implements OnInit {
  notificationList: NotificationList[] = [];
  isLoading = false;
  currentPage = 1;
  isRead: boolean;
  currentId: string;
  tabId: string;
  isSelectAll = true;
  selectButtonOptions: any[];
  selectedButton = 'all';
  isMenuShow: Record<string, boolean> = {};
  notificationMainOptions: any;
  notificationOptions: any = notificationsOptions;
  moment = moment;

  constructor(
    private notificationService: NotificationsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const tab = this.router.url.includes('unread') ? 'unread' : 'all';

    this.notificationMainOptions = [
      { label: 'Mark all as read', id: 'mark_all_as_read' },
    ];
    this.selectButtonOptions = [
      { label: 'All', value: 'all' },
      { label: 'Unread', value: 'unread' },
    ];
    if (tab) {
      this.selectedButton = tab;
      tab === 'all' && this.getNotifications(this.currentPage - 1);
      tab === 'unread' && this.getAllUnreadNotifications();
    }
  }

  getNotifications(page: number, loading = true) {
    this.isLoading = loading;
    this.notificationService.getNotificationsList(page).subscribe({
      next: (data: any) => {
        console.log('Request to get notifications list from the server .');

        const _list = data.map((d: NotificationList) => {
          d.parameters.forEach((p) => {
            d.message = d.message.replace(
              new RegExp(`{{(${p.key})}}`, 'gi'),
              `<strong>${p.value}</strong>`,
            );
          });
          return d;
        });
        _list.forEach((n: any) => {
          this.isMenuShow[n.id] = false;
        });
        this.notificationList = [...this.notificationList, ..._list];
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        console.error(
          'Request to get notifications list from the server errored out.',
        );
        console.error(err.message);
      },
    });
  }

  getAllUnreadNotifications() {
    this.notificationService
      .getAllUnreadNotifications(this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          console.log(
            'Request to get unread notification from the server succeeded',
          );
          const _list = data.map((d: NotificationList) => {
            d.parameters.forEach((p) => {
              d.message = d.message.replace(
                /{{(.+?)}}/,
                `<strong>${p.value}</strong>`,
              );
            });
            return d;
          });
          _list.forEach((n: NotificationList) => {
            this.isMenuShow[n.id] = false;
          });
          this.notificationList = [...this.notificationList, ..._list];
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          console.error(
            'Request to get unread notifications list from the server errored out.',
          );
          console.error(err.message);
        },
      });
  }

  mouseEvent(id: string, read: boolean) {
    this.currentId = id;
    this.isRead = read;
  }

  onScrolledDown() {
    this.currentPage++;
    if (this.selectedButton === 'all') {
      this.getNotifications(this.currentPage - 1, !1);
    } else {
      this.getAllUnreadNotifications();
    }
  }

  notificationAction(menuId: string) {
    if (menuId === 'mark_as_read' || menuId === 'mark_as_unread') {
      const method = menuId === 'mark_as_read' ? 'markAsRead' : 'markAsUnread';
      this.notificationService[method](this.currentId).subscribe({
        next: () => {
          console.log(
            'Request to mark as read notification from the server succeeded',
          );
          this.notificationList.forEach((n: NotificationList) => {
            if (n.id === this.currentId) {
              n.read = !n.read;
            }
          });
        },
        error: (err) => {
          console.error(
            'Request to mark as read from the server errored out. ' +
              err.message,
          );
        },
      });
    } else if (menuId === 'remove_notification') {
      this.notificationService.remove(this.currentId).subscribe({
        next: () => {
          console.log(
            'Request to remove notification from the server succeeded',
          );
          this.currentPage = 1;
          this.notificationList = [];
          this.getNotifications(this.currentPage - 1);
        },
        error: (err) => {
          console.error(
            'Request to remove notification from the server errored out. ' +
              err.message,
          );
        },
      });
    } else {
      this.notificationService.markAllAsRead().subscribe({
        next: () => {
          console.log('Request to mark all as read from the server succeeded');
          this.selectedButton === 'unread' && (this.notificationList = []);
          this.selectedButton === 'all' &&
            this.notificationList.forEach((n: NotificationList) => {
              n.read = true;
            });
        },
        error: (err) => {
          console.error(
            'Request to mark all as read from the server errored out. ' +
              err.message,
          );
        },
      });
    }
  }

  onSelectAction(e: any) {
    const { value } = e;
    this.currentPage = 1;
    if (value === 'all') {
      this.router.navigate(['notification']);
    } else if (value === 'unread') {
      this.router.navigate(['notification', 'unread']);
    }
  }

  redirectToTimeline(notification: NotificationList) {
    this.notificationAction('mark_as_read');

    switch (notification.type) {
      case 'NO_NAV':
        break;
      case 'CARPOOL':
        this.router.navigate(['/carpool', 'view', notification.refId]);
        break;
      case 'HUDDLE':
        this.router.navigate(['/huddle', 'view', notification.refId]);
        break;
      case 'CONNECTION':
        this.router.navigate(['/account', 'connections'], {
          queryParams: {
            tab: conncetionsMap[notification.refType],
            displayName: notification.refId,
          },
        });
        break;
      case 'COMMUNITY':
        this.router.navigate(['/community', 'view', notification.refId]);
        break;
      default:
        this.router.navigate([
          DEFAULT_HOME + '/timeline',
          'view',
          notification.refId,
        ]);
    }
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }
}
