<div class="flex max-w-full flex-col items-center">
  <div class="w-full">
    <div class="mx-auto max-w-[920px] p-2">
      <div class="py-4 text-center">
        <h4 class="text-2xl font-semibold text-teal-600">
          🥘 Potluck Planning Sheet
        </h4>
        <p class="text-white-700">
          Claim to bring an item and make the potluck a success!
        </p>
      </div>

      <!-- Existing Categories -->
      <div
        *ngFor="let category of potluckCategories; let categoryIndex = index"
        class="mt-10"
      >
        <!-- Add New Category Input -->
        <div class="relative px-2">
          <textarea
            [(ngModel)]="category.category"
            class="h-level-1 unread w-full resize-none border-0 bg-transparent p-0 !font-semibold font-semibold shadow-none outline-none"
            rows="1"
            maxlength="150"
            placeholder="Enter Potluck Category here"
          ></textarea>

          <button
            title="Delete Category"
            type="button"
            (click)="deleteCategory(categoryIndex)"
            pButton
            class="mp-button absolute right-2 top-2 rounded-md bg-transparent text-red-600 hover:bg-red-100"
          >
            <span class="material-icons">delete</span> Delete
          </button>
        </div>

        <p-card [style]="{ width: '100%' }">
          <div class="mb-4">
            <label class="mb-4 text-xl">Category items</label>
          </div>

          <ng-template pTemplate="content">
            <!-- Category Items -->
            <ul>
              <li
                *ngFor="let item of category.items; let itemIndex = index"
                class="mb-4"
              >
                <div class="flex items-center gap-4">
                  <!-- Display the number before the input item -->
                  <span class="mr-2 font-semibold">{{ itemIndex + 1 }}.</span>
                  <!-- Input field for item name -->
                  <input
                    pInputText
                    id="item-{{ categoryIndex }}-{{ itemIndex }}"
                    type="text"
                    [(ngModel)]="item.name"
                    placeholder="Enter item name"
                    class="block h-12 w-full rounded-md border px-2 py-1.5"
                  />
                  <!-- Remove Button -->
                  <button
                    title="Remove Item"
                    type="button"
                    (click)="removeItem(categoryIndex, itemIndex)"
                    pButton
                    class="mp-button mp-button-rounded h-6 w-6 justify-center rounded-full"
                  >
                    <span class="material-icons">close</span>
                  </button>
                </div>
              </li>
            </ul>

            <!-- Add Item Button -->
            <div class="mt-4 flex justify-center">
              <button
                type="button"
                (click)="addItemToCategory(categoryIndex)"
                class="mp-button mp-button-filled rounded-md px-5 py-2"
              >
                <span class="material-icons">add</span> Add Item
              </button>
            </div>
          </ng-template>
        </p-card>
      </div>

      <div class="mt-6 flex justify-center">
        <button
          type="button"
          (click)="addPotluckCategory()"
          class="mp-button mp-button-filled rounded-md px-5 py-2"
        >
          <span class="material-icons">add</span> Add Category
        </button>
      </div>

      <!-- Action Buttons -->
      <div class="my-6 flex justify-between gap-4">
        <button
          (click)="onCancel()"
          class="mp-button mp-button-outlined rounded-md px-5 py-2"
        >
          Cancel
        </button>
        <div>
          <button
            (click)="resetItems()"
            class="mp-button mp-button-outlined mr-2 rounded-md px-5 py-2"
          >
            Reset
          </button>
          <button
            (click)="saveCategories()"
            class="mp-button mp-button-filled rounded-md px-5 py-2"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
